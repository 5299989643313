
import { Button } from '@material-ui/core';
import React, { useRef, useState, useEffect, useCallback, useMemo} from 'react';
import { useTheme } from "@material-ui/styles";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $, { each } from 'jquery';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import MUIDataTable from "mui-datatables";
import mock from '../../pages/dashboard/mock';
import { Table } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { Typography,FormControl, InputLabel,  Paper, Box, Chi, Link, Chip, FormGroup, FormControlLabel, Accordion, AccordionSummary, AccordionDetails, Card, CardContent, FormLabel, RadioGroup, Radio, CardMedia, Divider } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import Fade from '@material-ui/core/Fade';
import { Avatar } from '@material-ui/core';
import { ControlPointOutlined, DoneAllOutlined } from '@material-ui/icons';
import { withRouter, Redirect, useHistory } from "react-router-dom";
import Iga3 from './Iga3.png';
import Patrycja from './Patrycja.jpg';
import StarIcon from '@material-ui/icons/Star';
import Kasia from './Kasia.jpg';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { ExpandMore }  from '@material-ui/icons';
import Push from 'push.js';
import {
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,

} from "@material-ui/core";
import Widget from "../../components/Widget";
import PageTitle from "../../components/PageTitle";
// styles


import { makeStyles } from '@material-ui/styles';
import parse from 'iab-vast-parser/lib/parse';
// components

const LGAnalyser = () => {
  const [appIds, setAppIds] = useState('');
  const [appData, setAppData] = useState([]);

  // Function to handle data fetch for multiple appIds
  const fetchData = async () => {
    const idsArray = appIds.split('\n').filter(id => id.trim() !== '');
    const data = [];

    for (let id of idsArray) {
      try {
        const response = await fetch(`https://us.lgappstv.com/api/tvapp/retrieveAppInfo.ajax?appId=${id.trim()}`);
        const result = await response.json();

        // Add fetched data or fallback in case of missing information
        data.push({
          appId: id.trim(),
          appName: result.prodInfo?.[0]?.appName || 'N/A',
          appStoreUrl: `https://us.lgappstv.com/main/tvapp/detail?appId=${id.trim()}`,
          avgSscr: result.prodInfo?.[0]?.avgSscr ? (Math.round((result.prodInfo[0].avgSscr / 2) * 2) / 2) : 'N/A',
          cat: result.prodInfo?.[0]?.cat || 'N/A',
          sellrUsrName: result.prodInfo?.[0]?.sellrUsrName || 'N/A',
          sellrUrl: result.prodInfo?.[0]?.sellrUrl || 'N/A',
        });
      } catch (error) {
        console.error(`Error fetching data for appId ${id}:`, error);
        // Push empty data for this appId in case of an error
        data.push({
          appId: id.trim(),
          appName: 'N/A',
          appStoreUrl: `https://us.lgappstv.com/main/tvapp/detail?appId=${id.trim()}`,
          avgSscr: 'N/A',
          cat: 'N/A',
          sellrUsrName: 'N/A',
          sellrUrl: 'N/A',
        });
      }
    }

    setAppData(data);
  };

  // Define columns for the MUIDataTable
  const columns = [
    {
      name: 'appId',
      label: 'App ID',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'appName',
      label: 'App Name',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'appStoreUrl',
      label: 'Store Url',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'avgSscr',
      label: 'Score',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'cat',
      label: 'Category',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'sellrUsrName',
      label: 'Developer',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'sellrUrl',
      label: 'Developer Page',
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  return (
    <>
      <h1>LG App Analyser</h1>
      <TextField
        label="App IDs (one per line)"
        multiline
        rows={4}
        variant="outlined"
        value={appIds}
        onChange={(e) => setAppIds(e.target.value)}
        fullWidth
      />
      <Button variant="contained" color="primary" onClick={fetchData} style={{ marginTop: '20px' }}>
        Fetch App Data
      </Button>
      <MUIDataTable
        title={'LG App Data'}
        data={appData}
        columns={columns}
        options={{
          filterType: 'checkbox',
        }}
      />
    </>
  );
};


const VerticalsList = () => {
  const [verticals, setVerticals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [domains, setDomains] = useState([]);
  const [newDomain, setNewDomain] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [authToken, setAuthToken] = useState('');
  const [progressEntries, setProgressEntries] = useState(0);
  const [maxEntries, setmaxEntries] = useState(0);
  const perPage = 100;
  const username = 'qa2-paris';
  const password = 'LzX@8!X6EhGier8pC9d4';
  const tableRef = useRef(null);

  const handleRefresh = () => {
    // Clear the cached verticals
    localStorage.removeItem('verticals');

    // Trigger the fetching of verticals again
    fetchVerticalsIfNeeded();
};
  const fetchAuthToken = async () => {
    try {
      const response = await fetch('https://eiqi.eqtv.io/api/v2/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          username,
          password,
          application: 'DamianQT',
        }),
      });
      const data = await response.json();
      setAuthToken(`${data.token_type} ${data.access_token}`);
    } catch (error) {
      console.error('Failed to fetch authentication token:', error);
      return null;
    }
  };

  const fetchVerticals = async (authToken, level = 0, page = 1, accumulatedData = []) => {
    try {
      const response = await fetch(
        `https://eiqi.eqtv.io/api/v2/contextual/verticals?filter[level]=${level}&per_page=${perPage}&page=${page}`,
        {
          headers: {
            Authorization: authToken,
          },
        }
      );
      const data = await response.json();
      const updatedData = [...accumulatedData, ...data.data];

      if (page < data.meta.last_page) {
        return fetchVerticals(authToken, level, page + 1, updatedData);
      } else {
        return updatedData;
      }
    } catch (error) {
      console.error(`Failed to fetch verticals for level ${level}:`, error);
      return accumulatedData;
    }
  };

  const buildHierarchy = (level0Data, level1Data, level2Data) => {
    return level0Data.map((level0Item) => {
      const level1Children = level1Data.filter((level1Item) =>
        level1Item.relationships.some((rel) => rel.id === level0Item.id)
      );

      const level1WithLevel2 = level1Children.map((level1Item) => {
        const level2Children = level2Data.filter((level2Item) =>
          level2Item.relationships.some((rel) => rel.id === level1Item.id)
        );

        return { ...level1Item, children: level2Children };
      });

      return { ...level0Item, children: level1WithLevel2 };
    });
  };

  useEffect(() => {
    const fetchAuthToken = async () => {
      try {
        const response = await fetch('https://eiqi.eqtv.io/api/v2/login', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            username,
            password,
            application: 'DamianQT',
          }),
        });
        const data = await response.json();
        const token = `${data.token_type} ${data.access_token}`;
        setAuthToken(token);
      } catch (error) {
        console.error('Failed to fetch authentication token:', error);
      }
    };
  
    fetchAuthToken();
  }, []); // Run only on initial mount to fetch token
  
  var level0Verticals;
  var level1Verticals;
  var level2Verticals;
  var fullHierarchy;
  const fetchVerticalsIfNeeded = async () => {
    if (!authToken) {
      console.log("Auth token not available, retrying...");
      return;
    }

    const cachedVerticals = localStorage.getItem('verticals');
    if (cachedVerticals) {
      console.log("Using cached verticals");
      setVerticals(JSON.parse(cachedVerticals));
      setLoading(false);
      return;
    }

    console.log("Fetching verticals...");
    level0Verticals = await fetchVerticals(authToken, 0);
    level1Verticals = await fetchVerticals(authToken, 1);
    level2Verticals = await fetchVerticals(authToken, 2);

    fullHierarchy = buildHierarchy(level0Verticals, level1Verticals, level2Verticals);
    localStorage.setItem('verticals', JSON.stringify(fullHierarchy));
    setVerticals(fullHierarchy);
    setLoading(false);
  };


  useEffect(() => {

  
    // Trigger fetching verticals when authToken is available
    fetchVerticalsIfNeeded();
  }, [authToken]); // Dependency on authToken to retry fetching verticals when token is set
  
  useEffect(() => {
    if (!authToken) {
      // Retry token fetch every few seconds if it fails initially
      const retryInterval = setInterval(() => {
        console.log("Retrying token fetch...");
        fetchAuthToken();
      }, 5000);
  
      // Clear interval once authToken is set
      return () => clearInterval(retryInterval);
    }
  }, [authToken]);

  const handleSelectionChange = (domain, level1Id, event) => {
    const { value } = event.target;
  
    // Store only IDs in `selectedOptions`
    setSelectedOptions((prev) => ({
      ...prev,
      [domain]: {
        ...prev[domain],
        [level1Id]: value, // Store array of selected IDs
      },
    }));
  };
  


  const fetchVerticalsForDomainOrBundle = async (type, domainOrBundleName) => {
    if (!authToken) return;
    try {
      const cachedVerticals = JSON.parse(localStorage.getItem('verticals') || '[]');
  
      const verticalIdToCategoryMap = {};
      cachedVerticals.forEach((level0Vertical) => {
        if (Array.isArray(level0Vertical.children)) {
          level0Vertical.children.forEach((level1Vertical) => {
            if (Array.isArray(level1Vertical.children)) {
              level1Vertical.children.forEach((level2Vertical) => {
                verticalIdToCategoryMap[level2Vertical.id] = {
                  categoryName: level1Vertical.name,
                  displayName: level2Vertical.name,
                };
              });
            }
          });
        }
      });
  
      const endpoint = type === 'domain' ? `/v2/contextual/domains` : `/v2/contextual/bundles`;
      const filterType = type === 'domain' ? 'domain' : 'bundle_id';
  
      const response = await fetch(`https://eiqi.eqtv.io/api${endpoint}?filter[${filterType}]=${domainOrBundleName}`, {
        method: 'GET',
        headers: {
          Authorization: authToken,
        },
      });
      const data = await response.json();
  
      if (data.data && data.data.length > 0) {
        const entry = data.data[0];
        if (entry && entry.verticals) {
          setSelectedOptions((prev) => {
            const updatedOptions = { ...prev[domainOrBundleName] };
  
            entry.verticals.forEach((vertical) => {
              const mappedCategory = verticalIdToCategoryMap[vertical.id];
              
              if (mappedCategory) {
                const { categoryName } = mappedCategory;
  
                if (!updatedOptions[categoryName]) {
                  updatedOptions[categoryName] = [];
                }
  
                if (!updatedOptions[categoryName].includes(vertical.id)) {
                  updatedOptions[categoryName].push(vertical.id);
                }
              } else {
                console.warn(`No category mapping found for vertical ID: ${vertical.id}`);
              }
            });
            return {
              ...prev,
              [domainOrBundleName]: updatedOptions,
            };
          });

        }
      } else {
        console.error(`No data found for ${type} ${domainOrBundleName}`);
      }
    } catch (error) {
      console.error(`Failed to fetch data for ${type} ${domainOrBundleName}:`, error);
    }
  };
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const handleAddDomain = async () => {
    if (newDomain) {
      // Set loading to true when the process starts
      setLoading(true);
  
      try {
        // Split the input by line breaks, trim whitespace, and filter out empty lines
        const entries = newDomain.split('\n').map((entry) => entry.trim()).filter(Boolean);
        setmaxEntries(entries.length);
  
        // Filter out duplicates that are already in the `domains` array
        const newEntries = entries.filter((entry) => !domains.includes(entry));
  
        // Update the domains list with new unique entries
        setDomains((prevDomains) => [...prevDomains, ...newEntries]);
  
        // Initialize progress tracking
        setProgressEntries(0);
  
        // Call the appropriate API for each new entry sequentially
        if (selectedType) {
          for (let i = 0; i < newEntries.length; i++) {
            const entry = newEntries[i];
  
            await fetchVerticalsForDomainOrBundle(selectedType, entry);
  
            // Update progress
            setProgressEntries(i + 1);
  
            // Wait for a specified delay
            await delay(1000); // Adjust delay as needed
          }
        } else {
          console.warn("Please select a type before adding entries.");
        }
  
        // Clear the input field
        setNewDomain('');
      } catch (error) {
        console.error("Error adding domains:", error);
      } finally {
        // Set loading to false when all tasks are completed
        setLoading(false);
      }
    }
  };


  const DOMAIN_COLUMNS = [
    "Equativ Segment", "IAB category", "Custom segments", "SimilarWeb category", "Gender", "Age", "Language", "Audience Type & Language", "Quality - Global Average", "Premium", "Brand-safe - Global Average", "Country Local Media", "US States Local Media", "US Cities Local Media"
  ];
  
  const BUNDLE_COLUMNS = [
    "Store", "Equativ Segment", "IAB category", "Custom segments", "Genre", "Device", "Player type", "Content Type", "Publisher type", "Gender", "Age", "Language", "Audience Type & Language", "Quality - Global Average", "Premium", "Brand-safe - Global Average", "Age Rating", "App Rating", "App Reviews", "App Download", "Country Local Media", "US States Local Media", "US Cities Local Media"
  ];
  
  const CTV_COLUMNS = [
    "Store", "Equativ Segment", "IAB category", "Custom segments", "Gender", "Age", "Language", "Audience Type & Language", "Fast channel", "OTT", "Channel", "Distributor", "Network", "Live", "AVOD", "CTV Categories", "CTV/OTT compliant", "CTV OOH (Out Of Home Connected TV)", "Code Station Letters", "Station Call Letters", "YouTube category", "YouTube type", "Quality - Global Average", "Premium", "Brand-safe - Global Average", "Age Rating", "App Rating", "App Reviews", "App Download", "Country Local Media", "US States Local Media", "US Cities Local Media"
  ];

  const STORE_OPTIONS = [
    "amazon", "android", "apple", "lg", "microsoft", "philips", "roku",
    "samsung", "sony_playstation", "vizio", "other"
  ];
  

  const generateColumns = () => {
    const cachedVerticals = JSON.parse(localStorage.getItem('verticals') || '[]');
    const verticalIdToNameMap = {};
    const uniqueCategories = new Set();
  
    // Build a map of vertical IDs to their display names and collect unique category names
    cachedVerticals.forEach((level0Vertical) => {
      if (Array.isArray(level0Vertical.children)) {
        level0Vertical.children.forEach((level1Vertical) => {
          uniqueCategories.add(level1Vertical.name); // Collect unique category names
          if (Array.isArray(level1Vertical.children)) {
            level1Vertical.children.forEach((level2Vertical) => {
              verticalIdToNameMap[level2Vertical.id] = level2Vertical.name; // Map ID to name for display
            });
          }
        });
      }
    });

    const typeColumns = selectedType === 'domain' ? DOMAIN_COLUMNS 
    : selectedType === 'bundle' ? BUNDLE_COLUMNS 
    : CTV_COLUMNS;
  
    // Generate columns based on unique category names
    const columns = Array.from(uniqueCategories)
    .filter((category) => typeColumns.includes(category))
    .map((category) => ({
      name: category,
      label: category,
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const domain = tableMeta.rowData[0];
          const selectedIds = selectedOptions[domain]?.[category] || [];
  
          return (

            <FormControl fullWidth>
              <Select
                multiple
                value={selectedIds}
                onChange={(e) => handleSelectionChange(domain, category, e)}
                renderValue={(selected) => (
                  <Box display="flex" flexWrap="wrap">
                    {selected.map((id) => (
                      <Chip key={id} label={verticalIdToNameMap[id] || id} style={{ margin: 2 }} />
                    ))}
                  </Box>
                )}
              >
                {/* Populate dropdown options based on all available verticals in the category */}
                {cachedVerticals
                  .flatMap((v) => v.children)
                  .filter((v) => v.name === category)
                  .flatMap((v) => v.children || [])
                  .map((level2Vertical) => (
                    <MenuItem key={level2Vertical.id} value={level2Vertical.id}>
                      {level2Vertical.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          );
        },
      },
    }));
  

    if (selectedType === 'bundle' || selectedType === 'ctv') {
      columns.splice(1, 0, {
        name: 'store',
        label: 'Store',
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            const domain = tableMeta.rowData[0];
            const selectedStore = selectedOptions[domain]?.store || [];
  
            return (
              <FormControl fullWidth>
                <Select
                  multiple
                  value={selectedStore}
                  onChange={(e) => handleSelectionChange(domain, 'store', e)}
                  renderValue={(selected) => (
                    <Box display="flex" flexWrap="wrap">
                      {selected.map((store) => (
                        <Chip key={store} label={store} style={{ margin: 2 }} />
                      ))}
                    </Box>
                  )}
                >
                  {STORE_OPTIONS.map((store) => (
                    <MenuItem key={store} value={store}>
                      {store}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          },
        },
      });
    }
  
    return columns;
  };
  
  // Helper function to get vertical names based on their IDs
  const getVerticalNames = (ids, level1Id) => {
    const level1 = verticals
      .flatMap((v) => v.children)
      .find((child) => child.id === level1Id);
  
    return ids
      .map((id) => level1?.children.find((vertical) => vertical.id === id)?.name)
      .filter(Boolean); // Filter out any undefined names
  };

  const generateData = () => {
    return domains.map((domain) => ({ domain }));
  };

  const handleDownload = () => {
    const rows = [];
  
    if (selectedType === 'domain') {
      // Add headers for domain type
      rows.push("Domain,Vertical ID,Score");
  
      Object.keys(selectedOptions).forEach((domain) => {
        Object.entries(selectedOptions[domain]).forEach(([category, selectedIds]) => {
          selectedIds.forEach((id) => {
            rows.push(`${domain},${id},100`); // Domain, Vertical ID, Score
          });
        });
      });
    } else if (selectedType === 'bundle' || selectedType === 'ctv') {
      // Add headers for bundle/ctv type
      rows.push("Bundle ID,Store ID,Vertical ID,Score");
  
      Object.keys(selectedOptions).forEach((bundleId) => {
        const storeIds = selectedOptions[bundleId].store || []; // Get selected stores for bundle/ctv
        Object.entries(selectedOptions[bundleId]).forEach(([category, selectedIds]) => {
          if (category !== 'store') { // Skip the store category as it's handled separately
            selectedIds.forEach((verticalId) => {
              storeIds.forEach((storeId) => {
                rows.push(`${bundleId},${storeId},${verticalId},100`); // Bundle ID, Store ID, Vertical ID, Score
              });
            });
          }
        });
      });
    }
  
    const csvContent = rows.join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
  
    const link = document.createElement('a');
    link.href = url;
    link.download = `${selectedType}_verticals.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getVerticalNameById = (verticalId) => {
    // Retrieve verticals data from localStorage
    const verticals = JSON.parse(localStorage.getItem('verticals')) || [];
  
    // Recursive function to search for vertical by id
    const findVerticalById = (id, verticals) => {
      for (let vertical of verticals) {
        if (vertical.id === id) {
          return vertical.name;
        }
        if (vertical.children && vertical.children.length > 0) {
          // Recursively check children if present
          const childVerticalName = findVerticalById(id, vertical.children);
          if (childVerticalName) {
            return childVerticalName;
          }
        }
      }
      return null; // Return null if not found
    };
  
    // Get the vertical name from the verticals data
    const verticalName = findVerticalById(verticalId, verticals);
  
    // Return the name if found, otherwise return a default value
    return verticalName ? verticalName : `Unknown ${verticalId}`;
  };
  
   return (
    <Paper elevation={3} style={{ padding: '30px', marginTop: '20px' }}>
      <Typography variant="h5" style={{ marginBottom: '20px' }}>Verticals Selection for Domains</Typography>
     <Grid>
      <div style={{textAlign:"center", margin:"10px"}}>
     <Button style={{textAlign:"center"}} variant="contained" color="primary" onClick={handleRefresh}>
     Refresh Verticals
 </Button>
      </div>

      </Grid>
      <FormControl fullWidth variant="outlined" style={{ marginBottom: '20px' }}>
        <Select
          value={selectedType}
          onChange={(e) => setSelectedType(e.target.value)}
          displayEmpty
        >
          <MenuItem value="" disabled>Select Type</MenuItem>
          <MenuItem value="domain">Domain</MenuItem>
          <MenuItem value="bundle">Bundle</MenuItem>
          <MenuItem value="ctv">CTV</MenuItem>
        </Select>
      </FormControl>

      <Box display="flex" alignItems="center" style={{ marginBottom: '20px' }}>
  <TextField
    label="Add Domains/Bundles/CTV Apps (separate entries by line breaks)"
    variant="outlined"
    value={newDomain}
    onChange={(e) => setNewDomain(e.target.value)}
    multiline // Enable multiline input
    rows={4} // Adjust the number of rows as needed
    style={{ flexGrow: 1, marginRight: '10px' }}
  />
  <Button variant="contained" color="primary" onClick={handleAddDomain}>
    Add
  </Button>
</Box>

      {loading ? (
        <>
                        <LinearProgress />
                        <Typography>
  Loading... 
  {maxEntries > 0 &&
    (() => {
      const remainingSeconds = (maxEntries - progressEntries) * 1; // Adjust multiplier if delay changes
      const minutes = Math.floor(remainingSeconds / 60);
      const seconds = remainingSeconds % 60;
      return `${progressEntries} / ${maxEntries} ${" "} (ETA: ${minutes > 0 ? `${minutes}m ` : ""}${seconds}s)`;
    })()}


</Typography>
        </>

      ) : (
        
        <MUIDataTable
          title={'Domain Segmentation'}
          data={generateData()}
          ref={tableRef}
          columns={[  {
            name: "domain",
            label: "Domain",
            options: {
              customBodyRender: (value) => {
                // Check if selectedType is "domain"
                return selectedType === "domain" ? (
                  <a href={`https://${value}`} target="_blank" rel="noopener noreferrer">
                    {value}
                  </a>
                ) : (
                  value
                );
              },
            },
          }, ...generateColumns()]}
          options={{
            selectableRows: 'none',
            responsive: 'standard',
            tableBodyHeight: '400px',
            downloadOptions: {
              filename: `${selectedType}_inventory.csv`,
              separator: ',',
            },
            storageKey: "table",
            onDownload: (buildHead, buildBody, columns, data) => {
              const rows = [];
              // Retrieve the verticals data from localStorage
              const verticals = JSON.parse(localStorage.getItem('verticals')) || {};
            
              // Generate the header dynamically using buildHead(columns) and remove any quotes
              const headers = buildHead(columns).map(header => header.replace(/"/g, '')); // Remove any double quotes
              rows.push(headers.join(',')); // Add the header row to the CSV content
            
              // Ensure the correct number of columns for each row based on the header
              const columnCount = headers.length;
            
              // Check if selectedType is 'domain'
              if (selectedType === 'domain') {
                // Iterate over selectedOptions for domains
                Object.keys(selectedOptions).forEach((domain) => {
                  const verticalCategories = {};
            
                  // Collect vertical names by category
                  Object.entries(selectedOptions[domain]).forEach(([category, selectedIds]) => {
                    selectedIds.forEach((id) => {
                      // Get the vertical name from the ID using localStorage data
                      const verticalName = getVerticalNameById(id) || `${id}`;
            
                      if (!verticalCategories[category]) {
                        verticalCategories[category] = [];
                      }
                      verticalCategories[category].push(verticalName); // Group vertical names by category
                    });
                  });
            
                  // Prepare the row, ensuring it matches the header structure
                  const row = headers.map((header) => {
                    if (header === "Domain") {
                      return domain; // Place the domain in the 'Domain' column
                    }
            
                    // Now check if the header matches any category, and add corresponding verticals
                    let verticalNames = [];
                    Object.keys(verticalCategories).forEach((category) => {
                      if (category === header) {
                        verticalNames = verticalCategories[category]; // Get verticals for this category
                      }
                    });
            
                    // If verticals exist for this category, join them with '|'. If not, leave the column empty
                    return verticalNames.length > 0 ? verticalNames.join('|') : '';
                  });
            
                  // Ensure the row has the same number of columns as the header
                  const paddedRow = [...row, ...new Array(columnCount - row.length).fill('')]; // Pad missing columns with empty values
            
                  rows.push(paddedRow.join(',')); // Add the formatted row to the rows array
                });
              } else if (selectedType === 'bundle' || selectedType === 'ctv') {
                // Iterate over selectedOptions for bundle/ctv
                Object.keys(selectedOptions).forEach((bundleId) => {
                  const storeIds = selectedOptions[bundleId].store || []; // Get selected stores for bundle/ctv
            
                  Object.entries(selectedOptions[bundleId]).forEach(([category, selectedIds]) => {
                    if (category !== 'store') { // Skip the store category as it's handled separately
                      selectedIds.forEach((verticalId) => {
                        storeIds.forEach((storeId) => {
                          // Get the vertical name from the ID using localStorage data
                          const verticalName = verticals[verticalId] || `Unknown ${verticalId}`;
            
                          // Prepare the row, ensuring it matches the header structure
                          const row = headers.map((header) => {
                            if (header === "Bundle ID") {
                              return bundleId; // Place the bundleId in the 'Bundle ID' column
                            }
                            if (header === "Store ID") {
                              return storeId; // Place the storeId in the 'Store ID' column
                            }
            
                            // Now check if the header matches any category, and add corresponding verticals
                            if (header === "Vertical") {
                              return verticalName; // Place the vertical name in the 'Vertical' column
                            }
            
                            // If there is no relevant data for this column, return an empty value
                            return '';
                          });
            
                          // Ensure the row has the same number of columns as the header
                          const paddedRow = [...row, ...new Array(columnCount - row.length).fill('')]; // Pad missing columns with empty values
            
                          rows.push(paddedRow.join(',')); // Add the formatted row to the rows array
                        });
                      });
                    }
                  });
                });
              }
            
              // Create CSV content with BOM (Byte Order Mark) for proper encoding
              const csvContent = "\uFEFF" + rows.join('\n');
            
              // Ensure only one download trigger occurs
              if (rows.length > 1) {
                // Create and trigger the download link
                const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = `${selectedType}_verticals.csv`; // Use the selected type for the file name
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              } else {
                console.error("No data to download");
              }
            }
          }}
        />
      )}

      <Box display="flex" justifyContent="center" style={{ marginTop: '20px' }}>
        <Button variant="contained" color="secondary" onClick={handleDownload} style={{ fontWeight: 'bold', padding: '10px 20px' }}>
          Download Selected Verticals
        </Button>

      </Box>
    </Paper>
  );
};



const Popup = () => {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const popupShown = getCookie('popupShown');
    setShowPopup(!popupShown);
    if (!popupShown) {
      setCookie('popupShown', true, 7); // Set cookie to expire in 7 days
    }
  }, []);

  const closePopup = () => {
    setShowPopup(false);
  };

  if (!showPopup) {
    return null; // Do not render the popup if it should not be shown
  }

  return (
    <div style={overlayStyle}>
      <div style={popupStyle}>
        <h2>Are you human?</h2>
        <p>Please verify that you are not Patrycja.</p>
        <button style={buttonStyle} onClick={closePopup}>Close</button>
      </div>
    </div>
  );
};

// Function to set a cookie
const setCookie = (name, value, days) => {
  const date = new Date();
  date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
  const expires = "expires=" + date.toUTCString();
  document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

// Function to get a cookie by name
const getCookie = (name) => {
  const cookieName = name + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(';');
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(cookieName) === 0) {
      return true;
    }
  }
  return false;
}

// Inline styles
const overlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const popupStyle = {
  backgroundColor: '#fff',
  padding: '20px',
  borderRadius: '8px',
  boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
};

const buttonStyle = {
  backgroundColor: '#007bff',
  color: '#fff',
  border: 'none',
  padding: '10px 20px',
  borderRadius: '4px',
  cursor: 'pointer',
};


const Countdown = () => {
  const [daysLeft, setDaysLeft] = useState(0);

  useEffect(() => {
    const countdownDate = new Date('2023-12-22T00:00:00Z').getTime();

    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countdownDate - now;
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));

      setDaysLeft(days);

      if (distance < 0) {
        clearInterval(interval);
        setDaysLeft(0); // Set to 0 if the countdown is finished
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div style={{ textAlign: "center" }}>
      <img style={{ borderRadius: "50%" }} src={Kasia} width="20%" alt="Kasia"></img><br></br>
      <b>Days left to say goodbye 😥 :  {daysLeft}</b>
      <hr></hr>
    </div>
  );
};


const RokuChecker = () => {
  const [data, setData] = useState({ feedChannel: {} });
  const [bundleData, setBundleData] = useState([]); // State to store bundle-wise data
  const text = useRef();
  const [tableData, setTableData] = useState([]);

  const fetchBundlesData = async (bundles) => {
    const batchSize = 20; // Number of fetches in each batch
    const bundleChunks = []; // Split bundles into chunks

    for (let i = 0; i < bundles.length; i += batchSize) {
      bundleChunks.push(bundles.slice(i, i + batchSize));
    }

    const fetchDataBatch = async (bundleChunk) => {
      const batchPromises = bundleChunk.map(async (bundle) => {
        try {
          const response = await fetch(
            `https://mobileappscrapper.herokuapp.com/html3/https://channelstore.roku.com/api/v6/channels/detailsunion/${bundle}`
          );
          if (!response.ok) {
            throw new Error(`Error fetching bundle: ${bundle}`);
          }
          const bundleData = await response.json();
          return bundleData;
        } catch (error) {
          console.error('Error fetching bundle:', error);
          return null;
        }
      });

      const batchResults = await Promise.all(batchPromises);
      return batchResults.filter((result) => result !== null);
    };

    const allBundleData = [];

    for (const chunk of bundleChunks) {
      const batchResults = await fetchDataBatch(chunk);
      allBundleData.push(...batchResults);
    }

    return allBundleData;
  };


  const columns = [
    {
      name: 'hdPosterUrl',
      label: 'Poster',
      options: {
        customBodyRender: (value) => {
          return (
            <img src={value} alt="Poster" style={{ width: '100px', height: 'auto' }} />
          );
        },
      },
    },
    { name: 'bundleId', label: 'bundleId' },
    { name: 'channelId', label: 'Channel ID' },
    { name: 'bundleurl', label: 'Bundle URL' },
    { name: 'name', label: 'Name' },
    { name: 'starRating', label: 'Star Rating' },
    {
      name: 'starRating',
      label: 'Star Rating',
      options: {
        customBodyRender: (value) => {
          const rating = parseFloat(value);
          const maxRating = 5;
          const starPercentage = (rating / 100) * maxRating;

          const stars = [];

          let remainingPercentage = starPercentage;

          for (let i = 0; i < maxRating; i++) {
            if (remainingPercentage >= 1) {
              stars.push(<StarIcon key={i} />);
            } else if (remainingPercentage >= 0.5) {
              stars.push(<StarHalfIcon key={i} />);
            } else {
              stars.push(<StarBorderIcon key={i} />);
            }

            remainingPercentage = Math.max(0, remainingPercentage - 1);
          }

          return (
            <div>
              {stars}
            </div>
          );
        },
      },
    },
    { name: 'starRatingCount', label: 'Star Rating Count' },
    
    { name: 'categories', label: 'Categories' },

    { name: 'developerUrl', label: 'developerUrl' },
    { name: 'privacyurl', label: 'privacypolicyurl'},

    { name: 'developer', label: 'Developer' },

  ];

  const formattedData = [data.feedChannel];


  const fetchChannelId = async (url) => {
    try {
      const response = await fetch(`https://mobileappscrapper.herokuapp.com/ctv/${encodeURIComponent(url)}`);
      const data = await response.json();
      if (data && data.docs && data.docs.length > 0) {
        return data.docs[0].appOverview.channelId;
      }
      return null;
    } catch (error) {
      console.error('Error fetching channel ID:', error);
      return null;
    }
  };
  
  const Scan = async () => {
    try {
      const inputBundles = text.current.value;
      const splitBundles = inputBundles.split('\n');
  
      const bundleData = [];
      for (const bundle of splitBundles) {
        const alphanumericMatch = bundle.match(/^([a-f0-9]{32})\/?$/i); // Alphanumeric format match
        const urlMatch = bundle.match(/^https:\/\/channelstore\.roku\.com\/(?:\w{2}-\w{2}\/)?(?:[a-z]+\/)?details\/(\d+)\/[\w-]+$/i); // Updated URL format match
        const numberMatch1 = bundle.match(/\/details\/(\d+)/);
        const numberMatch2 = bundle.match(/\/details\/(\d+)/);
        console.log(numberMatch1);
        console.log(numberMatch2);
        const numericMatch = bundle.match(/\/details\/([a-f0-9]{6,})/i); // Numeric format match for longer IDs
  
        if (numericMatch) {
          const numericId = numericMatch[1];
          bundleData.push(numericId); // Add numericId to fetch directly via fetchBundlesData
        } else {
          if (alphanumericMatch) {
            const alphanumericId = await fetchChannelId(alphanumericMatch[1]);
            if (alphanumericId) {
              bundleData.push(alphanumericId);
            }
          } else if (urlMatch) {
            const urlId = await fetchChannelId(urlMatch[1]);
            if (urlId) {
              bundleData.push(urlId);
            }
          }
          else
          {
            if (numberMatch1) {
              const extractedNumber1 = numberMatch1[1];
              let bund = await fetchChannelId(extractedNumber1);
              if(bund)
              {
                bundleData.push(bund);
              }
            } else {
              if (numberMatch2) {
                
                const extractedNumber2 = numberMatch2[1];
                let bund = await fetchChannelId(extractedNumber2);
                if(bund)
                {
                  bundleData.push(bund);
                }
              } else {
                console.log('No number found in url2');
              }
            }

         
           
          }
        }
      }
  
      const fetchedBundleData = await fetchBundlesData(bundleData);
      setBundleData(fetchedBundleData);
      console.log(fetchedBundleData);
    } catch (error) {
      console.error('Error scanning bundles:', error);
    }
  };
  const updateTableData = useCallback(() => {
    const formattedBundleData = bundleData.map((bundle) => ({
      
      name: bundle.feedChannel.name || '',
      starRating: bundle.feedChannel.starRating || '',
      starRatingCount: bundle.feedChannel.starRatingCount || '',
      categories: bundle.feedChannel.categories.map(category => category.name).join(', '),
      hdPosterUrl: bundle.feedChannel.hdPosterUrl || '',
      channelId: bundle.feedChannel.channelId || '',
      developer: bundle.feedChannel.developer || '',
      developerUrl: (bundle.details.currentDetail.channelStoreDetails.flatMap(detail => detail.appAdsTxtUrls)[0]) || '',
      bundleId: bundle.details.storeId || '',
      privacyurl: bundle.details.currentDetail.developerPrivacyUrl || '',
      bundleurl: "https://channelstore.roku.com/details/" + bundle.feedChannel.channelId
    }));

    setTableData(formattedBundleData);
  }, [bundleData]);

  useEffect(() => {
    updateTableData();
  }, [updateTableData]);



  return (
<>
<Grid container spacing={4}>
        <Grid item xs={12}>
        <Grid item xs={12}>
          <Widget>
<TextField placeholder="Insert Roku channel store urls like https://channelstore.roku.com/details/a20e3c294993147c6cda435497594031/apple-tv separated by line breaks " variant="outlined" inputRef={text} rows={10} fullwidth="true" multiline id="tocheck" style={{borderRadius:"10px",textAlign:"center",width:"100%",height:"200px",resize:"vertical"}} className="inline-txtarea"></TextField>
<div style={{alignItems:"center", textAlign:"center"}}>
<Button onClick={()=>{Scan();}} variant="contained" color="secondary" style={{marginTop:"40px", marginBottom:"40px"}}>Scan</Button>    
</div>
      
    <MUIDataTable
      title={'Roku Analyser'}
      data={tableData}
      columns={columns}
      options={{
        selectableRows: 'none', // Enable/disable row selection as needed
        // Add more MUIDataTable options as required
      }}
    />
    </Widget>
</Grid>
</Grid>
</Grid>
</>
  );
  
};


function AppAnalyzer() {
  // Default settings

 
  const defaultSettings = {
    minReviews: 10,
    minRating: 4,
    blockSensitive: true,
    blockUtility: true,
    requireDeveloperPage: true,
    requireAppAdsTxt: false,
    sensitiveKeywords:
      'bdsm, erotic, porn, vpn, launcher, wallpaper, keyboard, emoji, sticker, adblock, theme, battery booster, lockscreen, ringtones, calculator, widget, cleaner, flashlight, alarm clock, screensaver',
    categorySensitiveScore: 0,
    categoryNonSensitiveScore: 5,
    keywordSensitiveScore: 0,
    keywordNonSensitiveScore: 5,
    reviewScoreRanges: [
      { min: 10, max: 9999, score: 15 },
      { min: 10000, max: 149999, score: 25 },
      { min: 150000, max: Infinity, score: 35 },
    ],
    ratingScoreRanges: [
      { min: 3, max: 3.74, score: 15 },
      { min: 3.75, max: 4.49, score: 25 },
      { min: 4.5, max: 5, score: 35 },
    ],
  };

  // State variables
  const [appIds, setAppIds] = useState('');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [viewMode, setViewMode] = useState('table'); // 'table' or 'cards'

  // Settings state variables
  const [minReviews, setMinReviews] = useState(defaultSettings.minReviews);
  const [minRating, setMinRating] = useState(defaultSettings.minRating);
  const [blockSensitive, setBlockSensitive] = useState(defaultSettings.blockSensitive);
  const [blockUtility, setBlockUtility] = useState(defaultSettings.blockUtility);
  const [requireDeveloperPage, setRequireDeveloperPage] = useState(
    defaultSettings.requireDeveloperPage
  );
  const [requireAppAdsTxt, setRequireAppAdsTxt] = useState(defaultSettings.requireAppAdsTxt);
  const [sensitiveKeywords, setSensitiveKeywords] = useState(defaultSettings.sensitiveKeywords);

  // Score thresholds settings
  const [categorySensitiveScore, setCategorySensitiveScore] = useState(
    defaultSettings.categorySensitiveScore
  );
  const [categoryNonSensitiveScore, setCategoryNonSensitiveScore] = useState(
    defaultSettings.categoryNonSensitiveScore
  );

  const [keywordSensitiveScore, setKeywordSensitiveScore] = useState(
    defaultSettings.keywordSensitiveScore
  );
  const [keywordNonSensitiveScore, setKeywordNonSensitiveScore] = useState(
    defaultSettings.keywordNonSensitiveScore
  );

  const [reviewScoreRanges, setReviewScoreRanges] = useState(defaultSettings.reviewScoreRanges);

  const [ratingScoreRanges, setRatingScoreRanges] = useState(defaultSettings.ratingScoreRanges);

  // Load settings from localStorage on component mount
  useEffect(() => {
    const storedSettings = localStorage.getItem('appAnalyzerSettings');
    if (storedSettings) {
      const settings = JSON.parse(storedSettings);
      setMinReviews(settings.minReviews);
      setMinRating(settings.minRating);
      setBlockSensitive(settings.blockSensitive);
      setBlockUtility(settings.blockUtility);
      setRequireDeveloperPage(settings.requireDeveloperPage);
      setRequireAppAdsTxt(settings.requireAppAdsTxt);
      setSensitiveKeywords(settings.sensitiveKeywords);
      setCategorySensitiveScore(settings.categorySensitiveScore);
      setCategoryNonSensitiveScore(settings.categoryNonSensitiveScore);
      setKeywordSensitiveScore(settings.keywordSensitiveScore);
      setKeywordNonSensitiveScore(settings.keywordNonSensitiveScore);
      setReviewScoreRanges(settings.reviewScoreRanges);
      setRatingScoreRanges(settings.ratingScoreRanges);
    }
  }, []);

  // Save settings to localStorage whenever they change
  useEffect(() => {
    const settings = {
      minReviews,
      minRating,
      blockSensitive,
      blockUtility,
      requireDeveloperPage,
      requireAppAdsTxt,
      sensitiveKeywords,
      categorySensitiveScore,
      categoryNonSensitiveScore,
      keywordSensitiveScore,
      keywordNonSensitiveScore,
      reviewScoreRanges,
      ratingScoreRanges,
    };
    localStorage.setItem('appAnalyzerSettings', JSON.stringify(settings));
  }, [
    minReviews,
    minRating,
    blockSensitive,
    blockUtility,
    requireDeveloperPage,
    requireAppAdsTxt,
    sensitiveKeywords,
    categorySensitiveScore,
    categoryNonSensitiveScore,
    keywordSensitiveScore,
    keywordNonSensitiveScore,
    reviewScoreRanges,
    ratingScoreRanges,
  ]);

  // Reset settings to default values
  const resetToDefaults = () => {
    setMinReviews(defaultSettings.minReviews);
    setMinRating(defaultSettings.minRating);
    setBlockSensitive(defaultSettings.blockSensitive);
    setBlockUtility(defaultSettings.blockUtility);
    setRequireDeveloperPage(defaultSettings.requireDeveloperPage);
    setRequireAppAdsTxt(defaultSettings.requireAppAdsTxt);
    setSensitiveKeywords(defaultSettings.sensitiveKeywords);
    setCategorySensitiveScore(defaultSettings.categorySensitiveScore);
    setCategoryNonSensitiveScore(defaultSettings.categoryNonSensitiveScore);
    setKeywordSensitiveScore(defaultSettings.keywordSensitiveScore);
    setKeywordNonSensitiveScore(defaultSettings.keywordNonSensitiveScore);
    setReviewScoreRanges(defaultSettings.reviewScoreRanges);
    setRatingScoreRanges(defaultSettings.ratingScoreRanges);

    // Remove settings from localStorage
    localStorage.removeItem('appAnalyzerSettings');
  };

  // Parse sensitive keywords into an array
  const SENSITIVE_KEYWORDS = useMemo(
    () =>
      sensitiveKeywords
        .split(',')
        .map((keyword) => keyword.trim().toLowerCase())
        .filter((keyword) => keyword.length > 0),
    [sensitiveKeywords]
  );

  const UTILITY_GENRES = ['Utilities', 'Personalization'];


  function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  // Function to extract domain from URL
  const extractDomain = (url) => {
    try {
      const { hostname } = new URL(url);
      return hostname.replace('www.', '');
    } catch {
      return '';
    }
  };

  // Function to calculate average rating
  const calculateAverageRating = (ratings) => {
    const validRatings = ratings.filter((rating) => rating > 0);
    if (validRatings.length === 0) return 0;
    const sum = validRatings.reduce((acc, curr) => acc + curr, 0);
    return parseFloat((sum / validRatings.length).toFixed(2));
  };
  async function fetchWithRetry(url, options = {}, retries = 3, backoff = 1000) {
    for (let i = 0; i < retries; i++) {
      try {
        const response = await fetch(url, options);
        if (!response.ok) {
          throw new Error(`Request failed with status ${response.status}`);
        }
        return response;
      } catch (error) {
        console.warn(`Fetch attempt ${i + 1} failed: ${error.message}`);
        if (i < retries - 1) {
          // Poczekaj przed kolejną próbą
          await delay(backoff * (i + 1));
        } else {
          throw error;
        }
      }
    }
  }


  // Function to process iOS app data
  const processiOSAppData = (data, id) => {
    if (data.resultCount === 0) {
      return { id, platform: 'iOS', found: false };
    } else {
      const app = data.results[0];
      return {
        id,
        platform: 'iOS',
        found: true,
        name: app.trackCensoredName,
        bundleId: app.bundleId,
        averageRating: app.averageUserRating || 0,
        reviewCount: app.userRatingCount || 0,
        developerPage: app.sellerUrl || '',
        primaryGenre: app.primaryGenreName || '',
        appStoreUrl: app.trackViewUrl || '',
        iconUrl: app.artworkUrl100 || '',
        fullData: app // Include the entire app object here
      };
    }
  };

  // Function to process Android app data
  const processAndroidAppData = (data, id) => {
    // Parse the 'apps' property which is a JSON string
    const apps = data.apps ? JSON.parse(data.apps) : [];
    
    if (!apps.length || apps[0].name === 'No name') {
      return { id, platform: 'Android', found: false };
    } else {
      const app = apps[0];
  
      // Handle reviewCount which could be a number or a string
      let reviewCount = app.reviewCount || 0;
      if (typeof reviewCount === 'string') {
        reviewCount = parseInt(reviewCount.replace(/,| /g, '')) || 0;
      } else {
        reviewCount = parseInt(reviewCount) || 0;
      }
  
      // Extract ratings, ensuring we handle numbers correctly
      const ratings = [
        parseFloat(app.rating) || 0,
        parseFloat(app.ratingES) || 0,
        parseFloat(app.ratingFR) || 0,
        parseFloat(app.ratingDE) || 0,
        parseFloat(app.ratingPL) || 0,
        parseFloat(app.ratingIT) || 0,
      ];
  
      // Calculate average rating excluding ratings that are 0
      const averageRating = calculateAverageRating(ratings);
  
      // Return the processed app data, ensuring property names match
      return {
        id,
        platform: 'Android',
        found: true,
        name: app.name || 'N/A',
        bundleId: id,
        averageRating: averageRating,
        reviewCount,
        developerPage: app.developerPage || '',
        primaryGenre: app.genre || '',
        appStoreUrl: app.shopurl || `https://play.google.com/store/apps/details?id=${id}`,
        iconUrl: app.imageUrl || '',
      };
    }
  };

  // Function to calculate score based on user-defined criteria
  const calculateScore = (appData) => {
    let score = 0;

    // 3. Check category
    const containsSensitiveCategory =
      blockUtility && UTILITY_GENRES.includes(appData.primaryGenre);
    score += containsSensitiveCategory ? categorySensitiveScore : categoryNonSensitiveScore;

    // 4. Check keywords
    const nameWords = appData.name ? appData.name.toLowerCase().split(/\s+/) : [];
    const containsSensitiveKeyword = SENSITIVE_KEYWORDS.some((keyword) =>
      nameWords.includes(keyword)
    );
    score += containsSensitiveKeyword ? keywordSensitiveScore : keywordNonSensitiveScore;

    // 5. Review count
    const reviewCount = appData.reviewCount || 0;
    const reviewScore = reviewScoreRanges.find(
      (range) => reviewCount >= range.min && reviewCount <= range.max
    );
    score += reviewScore ? reviewScore.score : 0;

    // 6. Rating
    const averageRating = appData.averageRating || 0;
    const ratingScore = ratingScoreRanges.find(
      (range) => averageRating >= range.min && averageRating <= range.max
    );
    score += ratingScore ? ratingScore.score : 0;

    return score;
  };

  // Function to apply criteria to app data
  const applyCriteria = async (appData) => {
    const result = { ...appData };
  
    // 1. Basic checks
    result.hasMinReviews = appData.reviewCount >= minReviews; 
    result.hasHighRating = appData.averageRating >= minRating;
  
    // 2. Sensitive keywords, utility apps, developer page checks
    const nameWords = appData.name ? appData.name.toLowerCase().split(/\s+/) : [];
    result.containsSensitiveKeyword =
      blockSensitive && SENSITIVE_KEYWORDS.some((keyword) => nameWords.includes(keyword));
  
    result.isUtilityApp = blockUtility && UTILITY_GENRES.includes(appData.primaryGenre);
  
    result.hasDeveloperPage = requireDeveloperPage
      ? appData.developerPage && !appData.developerPage.endsWith('gmail.com')
      : true;
  
    // 3. App-ads.txt check
    result.hasAppAdsTxt = !requireAppAdsTxt; // If not required, default to true
    if (requireAppAdsTxt && result.hasDeveloperPage) {
      const developerDomain = extractDomain(appData.developerPage);
      if (developerDomain) {
        const appAdsTxtUrl = `https://mobileappscrapper.herokuapp.com/text/https://${developerDomain}/app-ads.txt`;
        try {
          const response = await fetch(appAdsTxtUrl);
          result.hasAppAdsTxt = response.ok;
        } catch {
          result.hasAppAdsTxt = false;
        }
      } else {
        result.hasAppAdsTxt = false;
      }
    }
  
    // 4. Score
    result.score = calculateScore(appData);
  
    // 5. Determine approval status and reason
    //    Collect the "raw" pass/fail of each criterion in an array
    const approvalCriteria = [
      result.hasMinReviews,
      result.hasHighRating,
      !result.containsSensitiveKeyword,
      !result.isUtilityApp,
      // If we require dev page, must have dev page; otherwise it's trivially "true"
      result.hasDeveloperPage || !requireDeveloperPage,
      // If we require app-ads.txt, must have it; otherwise "true"
      result.hasAppAdsTxt || !requireAppAdsTxt,
    ];
  
    // If every criterion passes, it's fully approved
    if (approvalCriteria.every((criterion) => criterion)) {
      result.approvalStatus = 'Approved';
      result.reason = 'Meets all criteria';
    }
    // --- EXAMPLE: If you want "Manual check" for insufficient reviews,
    //     insert a check here:
    else if (!result.hasMinReviews && appData.reviewCount === 0) {
      result.approvalStatus = 'Manual check';
      result.reason = 'Insufficient reviews';
    }
    // --- Or keep your existing "Manual check" logic based on score:
    else if (
      result.score >= 40 &&
      result.score < 55 &&
      !result.containsSensitiveKeyword &&
      !result.isUtilityApp
    ) {
      result.approvalStatus = 'Manual check';
      result.reason = 'Score between 40 and 55';
    } else {
      // Not approved, build up the list of reasons
      const reasons = [];
  
      // Only add "Low rating" if they actually had enough reviews to rate in the first place
      // and THEN the average is below minRating
      if (result.hasMinReviews && !result.hasHighRating && appData.averageRating > 3)
      {
        reasons.push('Low Quality')
      }
      else if (result.hasMinReviews && !result.hasHighRating) {
        reasons.push('Low rating');
      }
      
      // If below min reviews, that is definitely insufficient
      if (!result.hasMinReviews) {
        reasons.push('Insufficient reviews');
      }
      if (result.containsSensitiveKeyword) reasons.push('Sensitive keyword in name');
      if (result.isUtilityApp) reasons.push('Utility app');
      if (requireDeveloperPage && !result.hasDeveloperPage) reasons.push('No developer page');
      if (requireAppAdsTxt && !result.hasAppAdsTxt) reasons.push('No app-ads.txt found');
  
      result.approvalStatus = 'Not Approved';
      result.reason = reasons.join(', ');
    }
  
    return result;
  };
  

  // Function to fetch app data based on ID (Android apps)
  const fetchAppData = async (id) => {
    // Since iOS apps are fetched in batches, this function is only for Android apps
    const url = `https://mobileappscrapper.herokuapp.com/html2/${id}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      const appData = processAndroidAppData(data, id);
      const finalData = await applyCriteria(appData);
      return finalData;
    } catch (error) {
      return { id, platform: 'Android', found: false, error: error.message };
    }
  };

  function isNumericId(id) {
    return /^\d+$/.test(id);
  }
  // Handler for analyzing apps
  async function handleAnalyze() {
    const ids = appIds
      .split(/\n|,/)
      .map((id) => id.trim())
      .filter((id) => id);
  
    setResults([]);
    setLoading(true);
  
    // iOS: tylko numeryczne ID
    const iosIds = ids.filter((id) => isNumericId(id));
    // Android: wszystkie ID, które nie są w pełni numeryczne
    const androidIds = ids.filter((id) => !isNumericId(id));
  
    const allResults = [];
  
    // Przetwarzanie iOS
    if (iosIds.length > 0) {
      const batchSize = 100;
      const iosBatches = [];
      for (let i = 0; i < iosIds.length; i += batchSize) {
        iosBatches.push(iosIds.slice(i, i + batchSize));
      }
  
      for (let batchIndex = 0; batchIndex < iosBatches.length; batchIndex++) {
        const batch = iosBatches[batchIndex];
        try {
          const idsParam = batch.join(',');
          const url = `https://itunes.apple.com/lookup?id=${idsParam}`;
          const response = await fetchWithRetry(url, {}, 3, 1000);
          const data = await response.json();
  
          const resultsMap = {};
          if (data.results && data.results.length > 0) {
            data.results.forEach((appData) => {
              resultsMap[appData.trackId.toString()] = appData;
            });
          }
  
          for (let id of batch) {
            let appResult;
            if (resultsMap[id]) {
              const appData = processiOSAppData(
                { resultCount: 1, results: [resultsMap[id]] },
                id
              );
              appResult = await applyCriteria(appData);
            } else {
              appResult = { id, platform: 'iOS', found: false };
            }
            allResults.push(appResult);
            setResults([...allResults]);
          }
        } catch (error) {
          // W przypadku błędu dla batcha
          for (let id of batch) {
            const appResult = { id, platform: 'iOS', found: false, error: error.message };
            allResults.push(appResult);
            setResults([...allResults]);
          }
        }
        // Opcjonalne opóźnienie między batchami iOS
        if (iosBatches.length > 1 && batchIndex < iosBatches.length - 1) {
          await delay(500);
        }
      }
    }
  
    // Przetwarzanie Android (w tym również te nie-numeryczne, np. B00CK8T920)
    if (androidIds.length > 0) {
      const concurrencyLimit = 3;
      let activeRequests = 0;
      let currentIndex = 0;
  
      const processNext = () => {
        if (currentIndex >= androidIds.length && activeRequests === 0) {
          setLoading(false);
          return;
        }
        while (activeRequests < concurrencyLimit && currentIndex < androidIds.length) {
          const id = androidIds[currentIndex];
          currentIndex++;
          activeRequests++;
  
          fetchAppData(id) 
            .then((appResult) => {
              allResults.push(appResult);
              setResults([...allResults]);
              activeRequests--;
              processNext();
            })
            .catch((error) => {
              const appResult = { id, platform: 'Android', found: false, error: error.message };
              allResults.push(appResult);
              setResults([...allResults]);
              activeRequests--;
              processNext();
            });
        }
      };
  
      processNext();
    } else {
      setLoading(false);
    }
  }


  // Prepare data for MUIDataTable
  const columns = [
    {
      name: 'icon',
      label: 'Icon',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) =>
          value ? <Avatar variant="rounded" src={value} /> : 'N/A',
      },
    },
    { name: 'id', label: 'App ID' },
    { name: 'name', label: 'Name' },
    {
      name: 'appStoreUrl',
      label: 'Store URL',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) =>
          value ? (
            <Link href={value} target="_blank" rel="noopener">
              Link
            </Link>
          ) : (
            'N/A'
          ),
      },
    },
    { name: 'platform', label: 'Platform' },
    { name: 'averageRating', label: 'Avg. Rating' },
    { name: 'reviewCount', label: 'Reviews' },
    {
      name: 'developerPage',
      label: 'Developer Page',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) =>
          value ? (
            <Link href={value} target="_blank" rel="noopener">
              Link
            </Link>
          ) : (
            'N/A'
          ),
      },
    },
    {
      name: 'hasAppAdsTxt',
      label: 'App-ads.txt',
      options: {
        customBodyRender: (value) => (value ? 'Yes' : 'No'),
      },
    },
    { name: 'approvalStatus', label: 'Approval Status' },
    { name: 'reason', label: 'Reason for Result' },
    { name: 'score', label: 'Score' },
  ];

  const data = results.map((result) => ({
    icon: result.iconUrl,
    id: result.id,
    name: result.name || 'N/A',
    appStoreUrl: result.appStoreUrl,
    platform: result.platform,
    averageRating: result.averageRating || 0,
    reviewCount: result.reviewCount || 0,
    developerPage: result.developerPage,
    hasAppAdsTxt: result.hasAppAdsTxt,
    approvalStatus: result.approvalStatus,
    reason: result.reason,
    score: result.score || 0,
  }));

  const options = {
    filterType: 'checkbox',
    selectableRows: 'none',
    downloadOptions: {
      filename: `AppAnalysis_${new Date().toISOString().slice(0, 10)}.csv`,
    },
    responsive: 'standard',
  };

  // Handler for Minimum Reviews input
  const handleMinReviewsChange = (e) => {
    const value = e.target.value;
    if (value === '') {
      setMinReviews(0);
    } else {
      const number = parseInt(value, 10);
      if (!isNaN(number) && number >= 0) {
        setMinReviews(number);
      }
    }
  };

  // Handlers for updating review and rating score ranges
  const handleReviewScoreChange = (index, field, value) => {
    const newRanges = [...reviewScoreRanges];
    newRanges[index][field] = field === 'score' ? parseInt(value, 10) : parseFloat(value);
    setReviewScoreRanges(newRanges);
  };

  const handleRatingScoreChange = (index, field, value) => {
    const newRanges = [...ratingScoreRanges];
    newRanges[index][field] = field === 'score' ? parseInt(value, 10) : parseFloat(value);
    setRatingScoreRanges(newRanges);
  };

  return (
 
    <Box sx={{ padding: '16px', margin: 'auto' }}>
      {/* Header */}
      <Box
        sx={{
          padding: '16px',
          color: 'black',
          textAlign: 'center',
          borderRadius: '12px',
          marginBottom: '16px',
        }}
      >
        <Typography variant="h4" component="h1">
          App Analyzer 2.0 (Beta)
        </Typography>
      </Box>

      {/* Input Area */}
      <Card sx={{ padding: '16px', borderRadius: '12px', marginBottom: '16px' }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Enter App IDs
          </Typography>
          <TextField
            label="App IDs (separate by commas or new lines)"
            variant="outlined"
            multiline
            rows={6}
            value={appIds}
            onChange={(e) => setAppIds(e.target.value)}
            placeholder="e.g., com.example, 123456789"
            fullWidth
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleAnalyze}
            fullWidth
            sx={{ marginTop: '16px' }}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : 'Analyze'}
          </Button>
        </CardContent>
      </Card>

      {/* Settings */}
<Card sx={{ borderRadius: '12px', marginBottom: '16px' }}>
  {/* Approval Criteria Accordion */}
  <Accordion>
    <AccordionSummary
      expandIcon={<ExpandMore />}
      aria-controls="approval-criteria-content"
      id="approval-criteria-header"
    >
      <Typography variant="h6">Approval Criteria</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <FormGroup>
        <Typography variant="body1" gutterBottom>
          Minimum Number of Reviews
        </Typography>
        <TextField
          type="number"
          value={minReviews}
          onChange={handleMinReviewsChange}
          variant="outlined"
          fullWidth
        />
        <Typography variant="body1" sx={{ marginTop: '16px' }} gutterBottom>
          Minimum Average Rating: {minRating}
        </Typography>
        <Slider
          value={minRating}
          onChange={(e, val) => setMinRating(val)}
          valueLabelDisplay="auto"
          step={0.1}
          min={0}
          max={5}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={blockSensitive}
              onChange={(e) => setBlockSensitive(e.target.checked)}
            />
          }
          label="Block sensitive keywords"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={blockUtility}
              onChange={(e) => setBlockUtility(e.target.checked)}
            />
          }
          label="Block utility apps"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={requireDeveloperPage}
              onChange={(e) => setRequireDeveloperPage(e.target.checked)}
            />
          }
          label="Require developer page"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={requireAppAdsTxt}
              onChange={(e) => setRequireAppAdsTxt(e.target.checked)}
            />
          }
          label="Require app-ads.txt"
        />
      </FormGroup>
      <Typography variant="body1" sx={{ marginTop: '16px' }} gutterBottom>
        Sensitive Keywords
      </Typography>
      <TextField
        variant="outlined"
        multiline
        rows={2}
        value={sensitiveKeywords}
        onChange={(e) => setSensitiveKeywords(e.target.value)}
        placeholder="Enter sensitive keywords separated by commas"
        fullWidth
      />
    </AccordionDetails>
  </Accordion>

  {/* Scoring Thresholds Accordion */}
  <Accordion>
  <AccordionSummary
    expandIcon={<ExpandMore />}
    aria-controls="scoring-thresholds-content"
    id="scoring-thresholds-header"
  >
    <Typography variant="h6">Scoring Thresholds</Typography>
  </AccordionSummary>
  <AccordionDetails>
    <Grid container spacing={3}>
      {/* Category Score Settings */}
      <Grid item xs={12}>
        <Typography variant="body1" gutterBottom>
          Category Scores
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label="Sensitive Category Score"
              type="number"
              value={categorySensitiveScore}
              onChange={(e) =>
                setCategorySensitiveScore(parseInt(e.target.value, 10) || 0)
              }
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Non-Sensitive Category Score"
              type="number"
              value={categoryNonSensitiveScore}
              onChange={(e) =>
                setCategoryNonSensitiveScore(parseInt(e.target.value, 10) || 0)
              }
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>

      {/* Keyword Score Settings */}
      <Grid item xs={12}>
        <Typography variant="body1" gutterBottom>
          Keyword Scores
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label="Sensitive Keyword Score"
              type="number"
              value={keywordSensitiveScore}
              onChange={(e) =>
                setKeywordSensitiveScore(parseInt(e.target.value, 10) || 0)
              }
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Non-Sensitive Keyword Score"
              type="number"
              value={keywordNonSensitiveScore}
              onChange={(e) =>
                setKeywordNonSensitiveScore(parseInt(e.target.value, 10) || 0)
              }
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>

      {/* Review Count Score Settings */}
      <Grid item xs={12}>
        <Typography variant="body1" gutterBottom>
          Review Count Scores
        </Typography>
        {reviewScoreRanges.map((range, index) => (
          <Grid container spacing={2} key={index} sx={{ marginBottom: '8px' }}>
            <Grid item xs={4}>
              <TextField
                label="Min Reviews"
                type="number"
                value={range.min}
                onChange={(e) =>
                  handleReviewScoreChange(index, 'min', e.target.value)
                }
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Max Reviews"
                type="number"
                value={range.max === Infinity ? '' : range.max}
                onChange={(e) =>
                  handleReviewScoreChange(
                    index,
                    'max',
                    e.target.value === '' ? Infinity : e.target.value
                  )
                }
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Score"
                type="number"
                value={range.score}
                onChange={(e) =>
                  handleReviewScoreChange(index, 'score', e.target.value)
                }
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>
        ))}
      </Grid>

      {/* Rating Score Settings */}
      <Grid item xs={12}>
        <Typography variant="body1" gutterBottom>
          Rating Scores
        </Typography>
        {ratingScoreRanges.map((range, index) => (
          <Grid container spacing={2} key={index} sx={{ marginBottom: '8px' }}>
            <Grid item xs={4}>
              <TextField
                label="Min Rating"
                type="number"
                value={range.min}
                onChange={(e) =>
                  handleRatingScoreChange(index, 'min', e.target.value)
                }
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Max Rating"
                type="number"
                value={range.max}
                onChange={(e) =>
                  handleRatingScoreChange(index, 'max', e.target.value)
                }
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Score"
                type="number"
                value={range.score}
                onChange={(e) =>
                  handleRatingScoreChange(index, 'score', e.target.value)
                }
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  </AccordionDetails>
</Accordion>
<Box sx={{ padding: '16px', textAlign: 'center' }}>
          <Button variant="outlined" color="secondary" onClick={resetToDefaults}>
            Reset to Default
          </Button>
        </Box>
</Card>

      {/* View Mode Selection */}
      <Box sx={{ marginTop: '16px' }}>
        <FormLabel component="legend">View Mode</FormLabel>
        <RadioGroup
          row
          aria-label="view-mode"
          name="view-mode"
          value={viewMode}
          onChange={(e) => setViewMode(e.target.value)}
        >
          <FormControlLabel value="table" control={<Radio />} label="Table View" />
          <FormControlLabel value="cards" control={<Radio />} label="Card View" />
        </RadioGroup>
      </Box>

      {/* Results */}
      <Box sx={{ marginTop: '16px' }}>
        {loading && (
          <Typography variant="body1">
            Processing apps... ({results.length}/{appIds
              .split(/\n|,/)
              .filter((id) => id.trim()).length})
          </Typography>
        )}
        {!loading && results.length > 0 && viewMode === 'table' && (
          <MUIDataTable
            title="Analysis Results"
            data={data}
            columns={columns}
            options={options}
          />
        )}
{!loading && results.length > 0 && viewMode === 'cards' && (
  <Grid container spacing={2}>
    {results.map((result, index) => (
      <Grid item xs={12} key={index}>
        <Card sx={{ borderRadius: '12px', display: 'flex', flexDirection: 'column', height: '100%', maxWidth: 350, margin: 'auto' }}>
          
          <CardContent sx={{ flexGrow: 1, padding: '8px' }}>
          <Box sx={{ padding: 2, backgroundColor: '#e7eaeb', borderRadius: 20, boxShadow: 3, textAlign: "center", border:"1px solid", borderColor:'#fe5000' }}>
          {result.iconUrl && (
            <Box 
              sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                padding: '16px',
              }}
            >
              <Box
                component="img"
                src={result.fullData?.artworkUrl100 || result.iconUrl}
                alt={result.name}
                style={{borderRadius:"20px"}}
                sx={{
                  width: 60,
                  height: 60,
                  objectFit: 'contain',
                }}
              />
            </Box>
          )}
      {/* Title */}
      <Typography
        variant="h4"
        component="div"
        gutterBottom
        noWrap
        sx={{
          fontWeight: 'bold',
          color: '#3f51b5',
          textAlign: 'center',
          marginBottom: 2,
        }}
      >
        {result.name || 'N/A'}
      </Typography>

      {/* Basic Info */}
      <Typography
        variant="h6"
        color="textSecondary"
        gutterBottom
        noWrap
        sx={{
          fontWeight: '500',
          color: '#555555',
          textAlign: 'center',
          marginBottom: 1.5,
        }}
      >
        <strong>ID:</strong> {result.id} | <strong>Platform:</strong> {result.platform}
      </Typography>

      {/* Average Rating */}
      <Typography
        variant="h6"
        noWrap
        sx={{
          fontWeight: '500',
          color: '#ff9800',
          textAlign: 'center',
          marginBottom: 1.5,
        }}
      >
        <strong>Avg. Rating:</strong> {result.averageRating?.toFixed(2) || 'N/A'}
        {result.reviewCount ? ` (${result.reviewCount} reviews)` : ''}
      </Typography>

      {/* Score */}
      <Typography
        variant="h6"
        noWrap
        sx={{
          fontWeight: '500',
          color: '#4caf50',
          textAlign: 'center',
          marginBottom: 1.5,
        }}
      >
        <strong>Score:</strong> {result.score || 0}
      </Typography>

      {/* Approval Status */}
      <Typography
        variant="h6"
        noWrap
        sx={{
          fontWeight: '500'
        }}
        style={{
          fontWeight: '500',
          textAlign: 'center',
          color:
            result.approvalStatus === 'Approved'
              ? '#4caf50' // Green for Approved
              : result.approvalStatus === 'Not Approved'
              ? '#ff9800' // Orange for Not Approved
              : '#f44336', // Red for others
        }}
      >
        <strong>Approval Status:</strong> {result.approvalStatus}
      </Typography>

      {/* Reason */}
      <Typography
        variant="h6"
        noWrap
        sx={{
          fontWeight: '500',
          color: '#555555',
          textAlign: 'center',
        }}
      >
        <strong>Reason:</strong> {result.reason}
      </Typography>
    </Box>
            {/* iOS-specific details */}
            {result.platform === 'iOS' && result.fullData && (
              <>
              <Box></Box>
                {result.fullData.formattedPrice && (
                  <Typography variant="body2" noWrap>
                    <strong>Price:</strong> {result.fullData.formattedPrice}
                  </Typography>
                )}
                
                {result.fullData.primaryGenreName && (
                  <Typography variant="body2" noWrap>
                    <strong>Category:</strong> {result.fullData.primaryGenreName}
                  </Typography>
                )}

                {result.fullData.sellerName && (
                  <Typography variant="body2" noWrap>
                    <strong>Developer:</strong> {result.fullData.sellerName}
                  </Typography>
                )}

                {result.fullData.contentAdvisoryRating && (
                  <Typography variant="body2" noWrap>
                    <strong>Content Rating:</strong> {result.fullData.contentAdvisoryRating}
                  </Typography>
                )}

                {Array.isArray(result.fullData.advisories) && result.fullData.advisories.length > 0 && (
                  <Typography variant="body2" noWrap>
                    <strong>Advisories:</strong> {result.fullData.advisories.join('; ')}
                  </Typography>
                )}

                {result.fullData.minimumOsVersion && (
                  <Typography variant="body2" noWrap>
                    <strong>Minimum iOS:</strong> iOS {result.fullData.minimumOsVersion}
                  </Typography>
                )}
                
                {result.fullData.currentVersionReleaseDate && (
                  <Typography variant="body2" noWrap>
                    <strong>Last Update:</strong> {new Date(result.fullData.currentVersionReleaseDate).toLocaleDateString()}
                  </Typography>
                )}

                {/* Screenshots */}
                {Array.isArray(result.fullData.screenshotUrls) && result.fullData.screenshotUrls.length > 0 && (
                  <>
                    <Typography variant="body2" sx={{ marginTop: '8px' }}>
                      <strong>Screenshots:</strong>
                    </Typography>
                    <Box sx={{ display: 'flex', overflowX: 'auto', gap: 1, paddingY: '8px' }}>
                      {result.fullData.screenshotUrls.map((url, i) => (
                        <a href={url} key={i} target="_blank" rel="noopener noreferrer">
                          <Box
                            component="img"
                            src={url}
                            alt={`Screenshot ${i + 1}`}
                            sx={{
                              width: 80,
                              height: 'auto',
                              borderRadius: '4px'
                            }}
                          />
                        </a>
                      ))}
                    </Box>
                  </>
                )}

                {/* Short Description */}
                {result.fullData.description && (
                  <Accordion sx={{ marginTop: 1 }}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                      <Typography variant="body2"><strong>Description</strong></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap', fontSize: '0.85rem' }}>
                        {result.fullData.description.length > 300
                          ? result.fullData.description.slice(0, 300) + '...'
                          : result.fullData.description}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                )}

                {/* Release Notes if available */}
                {result.fullData.releaseNotes && (
                  <Accordion sx={{ marginTop: 1 }}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                      <Typography variant="body2"><strong>Release Notes</strong></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap', fontSize: '0.85rem' }}>
                        {result.fullData.releaseNotes}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                )}
              </>
            )}

            <Divider sx={{ marginY: 1 }} />



            <Divider sx={{ marginY: 1 }} />

            {/* Links */}
            <Box sx={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
              {result.appStoreUrl && (
                <Link href={result.appStoreUrl} target="_blank" rel="noopener">
                  Store
                </Link>
              )}
              {result.developerPage && (
                <Link href={result.developerPage} target="_blank" rel="noopener">
                  Developer Page
                </Link>
              )}
            </Box>
          </CardContent>
        </Card>
      </Grid>
    ))}
  </Grid>
)}





      </Box>
    </Box>
  );
}




export default function AppAnalyserBeta() {
  

  return (
    <div >

            <Grid>
            </Grid>


            

          <Paper>
          <AppAnalyzer></AppAnalyzer>
          </Paper>

    </div>
    
  );
  }
